<!--
* @description:
* @fileName shopManage.vue
* @author lxs
* @date 2022/08/30 14:21
!-->
<template>
  <div>
    <vab-query-form class="ml-10" style="padding-top: 20px">
      <vab-query-form-left-panel :span="24">
        <el-form :inline="true" :model="state.queryForm" @submit.prevent>
          <el-form-item label="持有人手机号">
            <el-input
              class="mr-10"
              placeholder="请输入持有人手机号"
              v-model="state.queryForm.mobile"
            ></el-input>
          </el-form-item>

          <el-form-item label="门票状态">
            <el-select
              v-model="state.queryForm.status"
              class="m-2"
              @change="handleChangeSelect"
              placeholder="请选择门票状态"
            >
              <el-option
                v-for="item in state.typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button icon="Search" type="primary" @click="handleQueryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table v-loading="loading" border :data="state.list">
      <el-table-column
        label="门票序列号"
        prop="idNo"
        show-overflow-tooltip
        align="center"
      >
        <template #default="{ row }">
          {{ row.idNo ? row.idNo : '暂无' }}
        </template>
      </el-table-column>
      <el-table-column
        label="门票名称"
        prop="ticketName"
        show-overflow-tooltip
        align="center"
      >
        <template #default="{ row }">
          {{ row.ticketName ? row.ticketName : '暂无' }}
        </template>
      </el-table-column>
      <el-table-column
        label="票种关联"
        prop="meetingName"
        show-overflow-tooltip
        align="center"
      >
        <template #default="{ row }">
          {{ row.meetingName ? row.meetingName : '暂无关联' }}
        </template>
      </el-table-column>
      <el-table-column
        label="门票金额(元)"
        prop="price"
        show-overflow-tooltip
        align="center"
      >
        <template #default="{ row }">
          {{ row.price ? row.price : '0.00' }}
        </template>
      </el-table-column>
      <el-table-column
        label="持有人姓名"
        prop="name"
        show-overflow-tooltip
        align="center"
      >
        <template #default="{ row }">
          {{ row.name ? row.name : '未设置' }}
        </template>
      </el-table-column>
      <el-table-column
        label="持有人手机号"
        prop="mobile"
        show-overflow-tooltip
        align="center"
      >
        <template #default="{ row }">
          {{ row.mobile ? row.mobile : '暂无' }}
        </template>
      </el-table-column>

      <el-table-column
        label="获得时间"
        prop="getTime"
        show-overflow-tooltip
        align="center"
      >
        <template #default="{ row }">
          {{ row.getTime ? filterTime(row.getTime) : '暂无' }}
        </template>
      </el-table-column>
      <el-table-column
        label="使用时间"
        prop="useTime"
        show-overflow-tooltip
        align="center"
      >
        <template #default="{ row }">
          {{ row.useTime ? filterTime(row.useTime) : '暂无' }}
        </template>
      </el-table-column>
      <el-table-column label="门票状态" prop="status" align="center">
        <template #default="{ row }">
          {{ field[row.status] }}
        </template>
      </el-table-column>

      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="300"
        align="center"
      >
        <template #default="{ row }">
          <el-button type="primary" @click="handleJumpOrderDetail(row)">
            订单详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination
      v-if="state.total > 10"
      background
      :layout="state.layout"
      :total="state.total"
      :page-sizes="state.pageSizes"
      v-model:page-size="state.queryForm.pageSize"
      v-model:currentPage="state.queryForm.pageNum"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
  export default { name: 'ticketsList' }
</script>
<script setup>
  import { onActivated } from 'vue'
  import { Search } from '@element-plus/icons'
  import { reactive, ref } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { useTicketList } from '@/api/exhibitionManage'
  import { parseTime } from '@/utils/index'
  import { debounce } from '@/utils/debounce'
  // 门票的状态
  const field = {
    null: '暂无',
    USED: '已使用',
    WAIT_USED: '待使用',
    EXPIRED: '已过期',
    CLOSED: '已关闭',
    NOT_START: '未开始',
    CAN_USED: '可使用',
  }
  // 引入路由实例
  const router = useRouter()
  const route = useRoute()
  // 引入组件
  const loading = ref(false) // 列表动画加载
  const state = reactive({
    pageSizes: [10, 20, 30],
    queryForm: { mobile: '', pageNum: 1, pageSize: 10, status: '', ticketCode: route.query.ticketCode },
    total: 0,
    list: [],
    layout: 'total, sizes, prev, pager, next, jumper',
    typeOptions: [
      {
        label: '全部',
        value: '',
      },
      {
        label: '未开始',
        value: 'NOT_START',
      },
      {
        label: '可使用',
        value: 'CAN_USED',
      },
      {
        label: '待使用',
        value: 'WAIT_USED',
      },
      {
        label: '已使用',
        value: 'USED',
      },
      {
        label: '已过期',
        value: 'EXPIRED',
      },
      {
        label: '已关闭',
        value: 'CLOSED',
      },
    ],
  })

  // 获取初始化数据
  const getData = async () => {
    loading.value = true
    const { data } = await useTicketList(state.queryForm)
    state.list = data.data
    state.total = data.total
    setTimeout(() => {
      loading.value = false
    }, 500)
  }
  // 筛选时间
  const filterTime = (val) => {
    return parseTime(val)
  }
  // 下拉事件
  const handleChangeSelect = (e) => {
    state.queryForm.status = e
  }
  // 查询
  const handleQueryData = debounce(() => {
    state.queryForm.pageNum = 1
    getData()
  })
  // 条数
  const handleSizeChange = debounce((pageSize) => {
    state.queryForm.pageSize = pageSize
    getData()
  })
  // 页码
  const handleCurrentChange = debounce((pageNum) => {
    state.queryForm.pageNum = pageNum
    getData()
  })
  // 跳转订单详情
  const handleJumpOrderDetail = (row) => {
    router.push({
      path: '/exhibitionManage/orderDetail',
      query: {
        orderNo: row.orderNo,
      },
    })
  }
  onActivated(async () => {
    state.queryForm.ticketCode = route.query.ticketCode
    console.log('执行1111')
    await getData()
  })
</script>
